import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import {
  LeadSection,
  Card,
  Title,
  CardGrid,
  Post,
  PostContentsContainer,
  PostContentsTitle,
  Link,
} from "@lleed-partners/factor";
import { ArrowRight32 } from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Icon } from "../fragments/Icon";
import { Layout } from "../layouts";
import { InsightCard } from "../fragments/Card/insight";
import { Helmet } from "gatsby-plugin-react-i18next";
import { asBackground } from "../fragments/styles";
import { StaticImage } from "gatsby-plugin-image";
import { trail } from "../fragments";

export default ({ data }) => {
  const { t } = useI18next();

  const node = data.caseStudiesAndInsights.edges[0].node;

  return (
    <Layout
      navProps={{
        showSubNav: false,
      }}
    >
      <Helmet>
        <meta
          name="description"
          content={t("lleedpartners.meta-description")}
        />
      </Helmet>
      <LeadSection
        intent="primary"
        padTop
        title={t("Insights and case studies")}
        subtitle={t(
          "Discover our latest insights into the digital world and selected projects that emphasize digitalization in businesses."
        )}
        backgroundMedia={
          <StaticImage
            src="../images/brand/6.jpg"
            alt=""
            style={asBackground}
            loading="eager"
          />
        }
        cta={
          <>
            {t("Latest insight")}
            <br />
            <br />
            <CardGrid>
              <InsightCard
                trackId={`insights-featured-latest-${node.frontmatter.slug}`}
                insight={node}
                key={node.frontmatter.slug}
                size="xLarge"
                intent="white"
                interactive
              />
            </CardGrid>
          </>
        }
      />
      <Post>
        <PostContentsTitle
          title={
            <Title
              title={t("All insights")}
              subtitle={t("Discover all our insights from newest to oldest")}
            />
          }
        />
        <PostContentsContainer position="center-right-full">
          <CardGrid intent="white" size="xLarge" bordered>
            {data.caseStudiesAndInsights.edges.map(({ node }) => (
              <InsightCard
                insight={node}
                key={node.frontmatter.slug}
                size="xLarge"
              />
            ))}
          </CardGrid>
        </PostContentsContainer>
      </Post>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    caseStudiesAndInsights: allMdx(
      filter: {
        fields: { langKey: { eq: $language } }
        frontmatter: {
          category: { in: ["insight", "case-study"] }
          published: { ne: false }
        }
      }
      sort: {
        fields: [frontmatter___modified, frontmatter___slug]
        order: DESC
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            excerpt
            headerImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            localHeaderImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
